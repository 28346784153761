export const headDivisionList = [
  "Chief Executive Officer",
  "Chief Operations Officer",
  "Chief Finance Officer",
  "Chief Technology & Marketing Officer",
  "Corporate Secretary",
  "Chief Strategy Officer",
  "Head Division",
  "Finance Manager"
];
